var $ = jQuery.noConflict();
var triggerBttn = document.getElementById('trigger-overlay'),
    overlay = document.querySelector('nav.overlay'),
    closeBttn = overlay.querySelector('a.overlay-close'),
    transEndEventNames = {
        'WebkitTransition': 'webkitTransitionEnd',
        'MozTransition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'msTransition': 'MSTransitionEnd',
        'transition': 'transitionend'
    },
    transEndEventName = transEndEventNames[Modernizr.prefixed('transition')],
    support = {
        transitions: Modernizr.csstransitions
    };

function toggleOverlay() {
    if (classie.has(overlay, 'open')) {
        classie.remove(overlay, 'open');
        classie.add(overlay, 'close');
        var onEndTransitionFn = function(ev) {
            if (support.transitions) {
                if (ev.propertyName !== 'visibility') return;
                this.removeEventListener(transEndEventName,
                    onEndTransitionFn);
            }
            classie.remove(overlay, 'close');
        };
        if (support.transitions) {
            overlay.addEventListener(transEndEventName, onEndTransitionFn);
        } else {
            onEndTransitionFn();
        }
    } else if (!classie.has(overlay, 'close')) {
        classie.add(overlay, 'open');
    }
}
triggerBttn.addEventListener('click', toggleOverlay);
closeBttn.addEventListener('click', toggleOverlay);
$(document).ready(function() {
        var spacer = $('#spacer');
        var nav = $(".navbar-fixed > nav");
        spacer.height(nav.height());
        $(window).resize(function(spacer, nav) {
            spacer.height(nav.height());
        });
    var triggerBttnSearch = document.getElementById(
            'trigger-overlay-search').parentNode,
        overlaySearch = document.querySelector('div.overlay-search'),
        closeBttnSearch = overlaySearch.querySelector('a.overlay-close'),
        transEndEventNames = {
            'WebkitTransition': 'webkitTransitionEnd',
            'MozTransition': 'transitionend',
            'OTransition': 'oTransitionEnd',
            'msTransition': 'MSTransitionEnd',
            'transition': 'transitionend'
        },
        transEndEventName = transEndEventNames[Modernizr.prefixed(
            'transition')],
        support = {
            transitions: Modernizr.csstransitions
        };

    function toggleOverlaySearch() {
        if (classie.has(overlaySearch, 'open')) {
            classie.remove(overlaySearch, 'open');
            classie.add(overlaySearch, 'close');
            var onEndTransitionFn = function(ev) {
                if (support.transitions) {
                    if (ev.propertyName !== 'visibility') return
                    ;
                    this.removeEventListener(transEndEventName,
                        onEndTransitionFn);
                }
                classie.remove(overlaySearch, 'close');
            };
            if (support.transitions) {
                overlaySearch.addEventListener(transEndEventName,
                    onEndTransitionFn);
            } else {
                onEndTransitionFn();
            }
        } else if (!classie.has(overlaySearch, 'close')) {
            classie.add(overlaySearch, 'open');
        }
    }
    triggerBttnSearch.addEventListener('click', toggleOverlaySearch);
    closeBttnSearch.addEventListener('click', toggleOverlaySearch);
    var triggerBttnSearchMenu = document.getElementById(
            'trigger-overlay-search-menu'),
        overlaySearchMenu = document.querySelector('div.overlay-search'),
        closeBttnSearchMenu = overlaySearchMenu.querySelector(
            'a.overlay-close');

    function toggleOverlaySearchMenu() {
        if (classie.has(overlaySearchMenu, 'open')) {
            classie.remove(overlaySearchMenu, 'open');
            classie.add(overlaySearchMenu, 'close');
            var onEndTransitionFn = function(ev) {
                if (support.transitions) {
                    if (ev.propertyName !== 'visibility') return
                    ;
                    this.removeEventListener(transEndEventName,
                        onEndTransitionFn);
                }
                classie.remove(overlaySearchMenu, 'close');
            };
            if (support.transitions) {
                overlaySearchMenu.addEventListener(
                    transEndEventName, onEndTransitionFn);
            } else {
                onEndTransitionFn();
            }
        } else if (!classie.has(overlaySearchMenu, 'close')) {
            classie.add(overlaySearchMenu, 'open');
        }
    }
    triggerBttnSearchMenu.addEventListener('click',
        toggleOverlaySearchMenu);
    closeBttnSearchMenu.addEventListener('click',
        toggleOverlaySearchMenu);
});
$('.slider').slider({
    indicators: true
});
/**
 * Materializecss JS
 */
//Tabs
$('ul.tabs').tabs();
// Add select option
$('select').material_select();
// Adds function to select option to control tabs on directory
$('select.locations').change(function() {
    $('ul.locations').tabs('select_tab', this.value);
});
//Add "input-field" for materializecss to work on text input fields
$("li.vfb-item").has('input.vfb-text').addClass(function(index) {
    return "input-field";
});
// Add radio button "<p>" tags for proper formatting
$(".vfb-span").has('input[type="radio"]').wrapInner("<p></p>");
//Change VFB grid to materializecss
$('li.vfb-item').each(function() {
    if ($(this).is('.vfb-left-half, .vfb-right-half')) {
        $(this).removeClass('vfb-left-half', 'vfb-right-half').addClass(
            'col s12 m6');
    } else {
        $(this).addClass('col s12');
    }
});
//Add collapsible accordian - Used on... Hours
var collapse = $('.collapsible');
var collapseHeader = collapse.find($(".collapsible-header"));
var open_hours = '<i class="material-icons open">keyboard_arrow_down</i>';
var close_hours = '<i class="material-icons close">keyboard_arrow_up</i>';
collapse.collapsible({
    accordion: true // A setting that changes the collapsible behavior to expandable instead of the default accordion style
});
collapseHeader.find($(".hours")).append(open_hours + close_hours);
collapse.on("click", function() {
    if ($(this).find($(".collapsible-header")).hasClass("active")) {
        $(this).find("li.active i.open").css("opacity", "0");
        $(this).find("li.active i.close").css("opacity", "1");
    } else {
        $(this).find("i.open").css("opacity", "1");
        $(this).find("i.close").css("opacity", "0");
    }
    $(this).siblings().find("i.open").css("opacity", "0");
    $(this).siblings().find("i.close").css("opacity", "1");
});
/**
 * Other JS
 */
// Briteverify, Mailchimp Form

        
		$('#subscribe').submit(function(e) {
			$('button[type=submit], input[type=submit]').attr('disabled',true);
			$('#arrow-load').addClass('fa-spinner faa-spin animated').removeClass('fa-chevron-right');
			var url = $('form.validate').attr('data-url');
				e.preventDefault();
				var time = microtime(true);
				if ( loadtime < time ) {
							if (!valid_email_address($("#email").val())) {
								$(".message").html('Please make sure you enter a valid email address.');
							} else {
								$.ajax({
									url: url+'/briteverify/check.php',
									data: $('#subscribe').serialize(),
									type: 'POST',
									success: function(data) {
										$(".message").html(data.message);
									}
								});
						}
				}
				else {
					document.open();
					document.write("");
					document.close();
				}
			setTimeout(function(){
				$('button[type=submit], input[type=submit]').attr('disabled',false);
				$('#arrow-load').addClass('fa-chevron-right').removeClass('fa-spinner faa-spin animated');
			},2000);	
		});

		$('#show-hide').toggle(
			function() {
				$('.jcontent').slideDown("fast");
			},
			function() {
				$('.jcontent').slideUp("fast");
			}
		);
// Get time for loading - this is used to prevent bots from using the form by stopping a fast submission.
	function microtime(get_as_float) {
		var unixtime_ms = new Date().getTime();
			var sec = parseInt(unixtime_ms / 1000);
			return get_as_float ? (unixtime_ms/1000) : (unixtime_ms - (sec * 1000))/1000 + ' ' + sec;
	}
//Check email address for proper formatting
	function valid_email_address(email) {
		var pattern = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i);
		return pattern.test(email);
	}


// Add sorting to table
if ($("#tablevars tr th").length) {
    var tableheaders = $("#tablevars tr th").map(function() {
        return $(this).data("field");
    });
} else {
    var tableheaders = ["units", "name", "address"];
}
var fuzzyOptions = {
    searchClass: "fuzzy-search",
    location: 0,
    distance: 100,
    threshold: 0.4,
    multiSearch: true
};
$(document).ready(function() {
    var tableOptions = {
        valueNames: tableheaders,
        plugins: [
            ListFuzzySearch()
        ]
    };
    var list = [];
    $("#directory .units").each(function(i) {
        var count = $(this).find('.unit').length;
        if (count > 0) {
            var parent = $(this).parent().attr('id');
            list[i] = new List(parent, tableOptions);
        }
    });
    /**
     * Add search filter
     */
    $(".fuzzy-searching").keyup(function() {
        var that = this;
        $(list).each(function(i) {
            this.search($(that).val());
        });
    });
});
if ($(".slider .indicators").length) {
    var height = $('.slider').height();
    var slider = $('.slider').height(height - 40);
}
/**
 * Load SVG Pan Zoom Map
 */
if ($('#map').length) {
    document.getElementById('map').addEventListener('load', function() {
        var eventsHandler;
        eventsHandler = {
            haltEventListeners: ['touchstart', 'touchend',
                'touchmove', 'touchleave', 'touchcancel'
            ],
            init: function(options) {
                var instance = options.instance,
                    initialScale = 1,
                    pannedX = 0,
                    pannedY = 0;
                // Init Hammer
                // Listen only for pointer and touch events
                this.hammer = Hammer(options.svgElement, {
                    inputClass: Hammer.SUPPORT_POINTER_EVENTS ?
                        Hammer.PointerEventInput : Hammer
                        .TouchInput
                });
                // Enable pinch
                this.hammer.get('pinch').set({
                    enable: true
                });
                // Handle double tap
                this.hammer.on('doubletap', function(ev) {
                    instance.zoomIn();
                });
                // Handle pan
                this.hammer.on('panstart panmove', function(ev) {
                    // On pan start reset panned variables
                    if (ev.type === 'panstart') {
                        pannedX = 0;
                            pannedY = 0;
                    }
                    // Pan only the difference
                    instance.panBy({
                        x: ev.deltaX - pannedX,
                        y: ev.deltaY - pannedY
                    });
                    pannedX = ev.deltaX;
                        pannedY = ev.deltaY;
                });
                // Handle pinch
                this.hammer.on('pinchstart pinchmove', function(
                    ev) {
                    // On pinch start remember initial zoom
                    if (ev.type === 'pinchstart') {
                        initialScale = instance.getZoom();
                        instance.zoom(initialScale * ev
                            .scale);
                    }
                    instance.zoom(initialScale * ev.scale);
                });
                // Prevent moving the page on some devices when panning over SVG
                options.svgElement.addEventListener('touchmove',
                    function(e) {
                        e.preventDefault();
                    });
            },
            destroy: function() {
                this.hammer.destroy();
            }
        };
        // Expose to window namespace for testing purposes
        panZoom = svgPanZoom('#map', {
            zoomEnabled: true,
            controlIconsEnabled: false,
            zoomScaleSensitivity: 0.50,
            mouseWheelZoomEnabled: false,
            minZoom: 1,
            maxZoom: 14,
            fit: 1,
            center: 1,
            customEventsHandler: eventsHandler
        });
        document.getElementById('zoom-in').addEventListener('click',
            function(ev) {
                ev.preventDefault();
                panZoom.zoomIn();
            });
        document.getElementById('zoom-out').addEventListener('click',
            function(ev) {
                ev.preventDefault();
                panZoom.zoomOut();
            });
        document.getElementById('reset').addEventListener('click',
            function(ev) {
                ev.preventDefault();
                panZoom.fit();
            });
        $('#full-screen').click(function(ev) {
            ev.preventDefault();
            mapFullscreen();
        });
    });
}
// Map Fullscreen mode
    function mapFullscreen(callback) {
        $('.slider').toggleFullScreen();
        $('.slides, .slider').toggleClass("fwidthheight");
        $('#full-screen i').text(function() {
            var e = $('#full-screen i');
            if (e.html() == "fullscreen") {
                return "fullscreen_exit";
            } else {
                return "fullscreen";
            }
        });
        var timer = setTimeout(function() {
            resizeSvg();
        }, 300);
    }

// Map resize, update to center
    function resizeSvg() {
        panZoom.resize(); // update SVG cached size and controls positions
        panZoom.fit();
        panZoom.center();
    }
    
// jQuery code:
// In Your Neighborhood Card hover
function cardHover() {
    $('.card.hover').hover(function(e) {
        $(".activator", this).trigger('click'); //raise card
    }, function(e) {
        $("i:contains('close')", this).trigger('click'); //close card
        //$(this).find(".card-reveal.fade").css("opacity","0"); //FADING CARDS - dev
    });
}
$(".card.hover .activator").on("click", toggleFade);

function toggleFade() {
    $(this).closest('card').find(".card-image .card-title").fadeOut("Fast");
    //$(this).closest('card').find(".card-reveal.fade").css("opacity","1"); //FADING CARDS - dev
}
$(document).ready(function() {
    cardHover();
    $("form.search-form a").click(function() {
        $("form.search-form input").toggleClass("active");
    }); // This would be the <a>'s parent <li>.
    var items = $(".filter-item");
    var banner = $('.banner-divider.category');
    $(window).resize(function() {
        var backdrop = banner.find($('.backdrop'));
        var left = backdrop.offset();
        backdrop.offset({
            left: -left
        });
    });
    $(".filters a.filter").on("click", (function() {
        $('.click-to-toggle > a').trigger('click'); //close card
        var cat = $(this).data("filter");
        var cat_color = $(this).data("color");
        var icon = $('i', this).parent().html();
        if (typeof icon == 'undefined') {
           icon = "";
        }
        $(".filters a.filter").removeClass("active");
        $(this).addClass("active");
        $('.filters a.filter').css('color', 'inherit');
        $(this).css('color', cat_color);
        $.when(items.fadeOut("fast")).then(function() {
            $('.filter-item[data-categories*="' +
                cat + '"]').fadeIn("fast");
        });
        banner.find($('.backdrop')).css('background-color',
            cat_color);
        banner.find($('h1')).html(icon + cat);
        delete window.cat_color;
        delete window.icon;
    }));
});
$(".eo-fullcalendar-loading").html(
    '<div class="preloader-wrapper big active"><div class="spinner-layer spinner-blue"><div class="circle-clipper left"><div class="circle"></div></div><div class="gap-patch"><div class="circle"></div></div><div class="circle-clipper right"><div class="circle"></div></div></div>'
);
$(document).ready(function() {
    $(".eo-fullcalendar").on('click', 'a.fc-event', function(e) {
        var modal_event = $('#modal-event');
					var qtip = "#qtip-" + $(this).data("hasqtip"); // get all data from custom tooltip
					getEventInfo(qtip, modal_event, e);
});
});
//Create modal
function getEventInfo(qtip, modal_event, e) {
	e.preventDefault();
        // Grab data form tooltip to use on modal popup
        var title = $(qtip).find(".qtip-title").text();
        var image = $(qtip).find(".qtip-image").text();
        var link = $(qtip).find(".qtip-link").text();
        var schedule = $(qtip).find(".qtip-schedule").text();
        var date = $(qtip).find(".qtip-date").text();
        var description = $(qtip).find(".qtip-description").html();
        // Add data to Modal
        modal_event.find('.modal-content').css("height", "calc(70% - 56px)");
        modal_event.find('.image h4.title').text(title);
        modal_event.find('.modal-content > h4.title').text("");
        modal_event.find('.image').css({
            "background": "url(" + image +
                ")center center no-repeat",
            "display": "block"
        });
        modal_event.find('p.event-schedule').text(schedule);
        modal_event.find('p.date').text(date);
        modal_event.find('p.description').html(description);
        modal_event.find('a.modal-action').attr("href", link);
        // Empty Image
        if (image === "") {
            modal_event.find('.modal-content > h4.title').text(title);
            modal_event.find('.image').css("display", "none");
            modal_event.find('.modal-content').css("height", "calc(100% - 56px)");
        }
	openModal(modal_event, e);
}
// Open Modal
    function openModal(modal_event, e) {
        e.preventDefault();
					modal_event.openModal();
					
}

// SHOW MORE ADD AS A CLASS TO THE UL
$(document).ready(function() {
    $(".longList").map(function(list, loadMore) { //maps out all elements with class and performs function
        size_li = $(this).find('> li ').size(); //count list items
        var x = 4; //how many items displayed by default
        if (size_li > x) { //if more than default items...
            $(this).find('.loadMore').css("display", "block"); //display "more" button
            $(this).find('li:lt(' + x + ')').show(); //show the default items
            $(this).find('.loadMore').click("size_li", function() {
                list = $(this).parent();
                x = (x + 3 <= size_li) ? x + 3 :
                    size_li; //add to the default items
                console.log($(list).children().find(
                    'li:lt(' + x + ')'));
                $(list).find('li:lt(' + x + ')').fadeIn(); //display all items added
                if ($(list).find('li:visible').length ==
                    size_li) { //if all items are visible
                    $(list).find('.loadMore').remove(); //remove the "more" button
                }
            });
        } else { //if not more than default items
            $(this).children('li:lt(' + x + ')').show(); //display default items
        }
    }).get();
	
// HIDE Event sections that are empty.
		$('ul.list').each(function() {

    if(!$(this).has('li').length) $(this).hide();
});
	
// HIDE Slider indicators if there is only one slide
	console.log($('ul.indicators').children().length);
		if(($('ul.indicators').children().length) < 2){$('ul.indicators').hide();}

});